.chatBotIconContainer{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: fixed;
    bottom:20px;
    right : 20px;
    background-color: white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    line-height: 50%;
  }
  
  .chatBotIcon {
    color : #6345C7;
    /* position: absolute; */
    top : 12px;
    left: 10px;
  }

  /* .chatBot {
    position: fixed;
    right : 50px;
    float: right;
    bottom : 85px;
    z-index: 5000;
  } */



